import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { LayoutBox } from "../../atoms/LayoutBox";

export const SupportingCompanies = () => (
  <div className="section has-background-light-blue p-0">
    <LayoutBox>
      <div className="content">
        <h2 className="subtitle is-size-6-mobile is-size-6-tablet is-size-6-widescreen is-italic has-text-weight-light">
          Supported by
        </h2>
      </div>
      <div className="columns is-vcentered is-multiline">
        <div className="column is-2 has-text-centered">
          <a
            href="https://www.health.org.uk/funding-and-partnerships/programmes/tech-for-better-care"
            target="_blank"
            rel="noreferrer"
          >
            <figure className="image">
              <StaticImage
                src="../../../../static/img/health-foundation.png"
                alt="the-health-foundation"
              />
            </figure>
          </a>
        </div>
        <div className="column is-2 has-text-centered">
          <a
            href="https://bethnalgreenventures.com/"
            target="_blank"
            rel="noreferrer"
          >
            <figure className="image">
              <StaticImage
                src="../../../../static/img/bgv.svg"
                alt="neumind-bgv"
              />
            </figure>
          </a>
        </div>
        <div className="column is-2 has-text-centered">
          <a
            href="https://www.ukri.org/councils/innovate-uk/"
            target="_blank"
            rel="noreferrer"
          >
            <figure className="image">
              <StaticImage
                src="../../../../static/img/innuk.png"
                alt="neumind-innuk"
              />
            </figure>
          </a>
        </div>
        <div className="column is-2 has-text-centered">
          <a
            href="https://www.theguardian.com/technology/2022/may/29/inventor-of-brain-injury-app-wins-second-young-innovators-prize"
            target="_blank"
            rel="noreferrer"
          >
            <figure className="image">
              <StaticImage
                src="../../../../static/img/The_Guardian_2018.svg"
                alt="neumind-guardian-article"
              />
            </figure>
          </a>
        </div>
        <div className="column is-2 has-text-centered">
          <a
            href="https://ukabif.org.uk/page/MikeBarnesAwardforInnovation_2020"
            target="_blank"
            rel="noreferrer"
          >
            <figure className="image">
              <StaticImage
                src="../../../../static/img/ukabif.png"
                alt="neumind-ukabif-award"
              />
            </figure>
          </a>
        </div>

        <div className="column is-2">
          <div className="columns is-centered is-mobile">
            <div className="column pr-1">
              <a
                href="https://www.sbs.ox.ac.uk/news/oxfo-announce-elevate-accelerator-cohort-4-ventures"
                target="_blank"
                rel="noreferrer"
              >
                <figure className="image">
                  <img
                    src="/img/oxweb-logo-square.svg"
                    alt="neumind-oxford"
                    style={{ backgroundColor: "#002147" }}
                  />
                </figure>
              </a>
            </div>
            <div className="column pl-1">
              <a
                href="https://www.sbs.ox.ac.uk/news/oxfo-announce-elevate-accelerator-cohort-4-ventures"
                target="_blank"
                rel="noreferrer"
              >
                <figure className="image">
                  <img src="/img/oxfo-logo.svg" alt="neumind-oxfo" />
                </figure>
              </a>
            </div>
          </div>
        </div>

        <div className="column is-2 has-text-centered">
          <a href="https://eithealth.eu/" target="_blank" rel="noreferrer">
            <figure className="image">
              <StaticImage
                src="../../../../static/img/EITHealth.png"
                alt="neumind-eithealth"
              />
            </figure>
          </a>
        </div>
      </div>
    </LayoutBox>
  </div>
);
