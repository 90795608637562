import React from "react";
import { LayoutBox } from "../atoms/LayoutBox";

export const TextSection = ({
  title = "",
  mainText = "",
  hasAltBackgroundColor = false,
  children,
}) => {
  return (
    <LayoutBox hasAltBackgroundColor={hasAltBackgroundColor}>
      <h1
        className="title is-size-4-mobile is-size-3-tablet is-size-2-widescreen pl-0 pr-0"
        style={{
          lineHeight: "1",
        }}
      >
        {title}
      </h1>
      {mainText && (
        <h2
          className="subtitle has-text-weight-light is-size-6-mobile is-size-6-tablet is-size-5-widescreen pt-4 pl-0 pr-0"
          style={{
            lineHeight: "1.4",
          }}
        >
          {mainText}
        </h2>
      )}
      {children}
    </LayoutBox>
  );
};
