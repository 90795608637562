import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import { IndexPageTemplate } from "./IndexPageTemplate";
import useSiteMetadata from "../components/SiteMetadata";

const IndexPage = ({ data }) => {
  const { frontmatter: indexPageFrontmatter } = data.markdownRemark;
  const { frontmatter: ourStoryFrontmatter } = data.ourStory;
  const { description: siteDescription } = useSiteMetadata();

  return (
    <Layout>
      <IndexPageTemplate
        title={indexPageFrontmatter.title}
        subheading={indexPageFrontmatter.subheading}
        storyIntro={indexPageFrontmatter.storyIntro}
        whatWeDo={indexPageFrontmatter.whatWeDo}
        ourStoryTitle={ourStoryFrontmatter.title}
        ourStoryFeaturedImage={ourStoryFrontmatter.featuredimage}
        description={siteDescription}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subheading
        storyIntro
        whatWeDo
      }
    }
    ourStory: markdownRemark(
      frontmatter: {
        templateKey: { eq: "blog-post" }
        title: { eq: "Our Story" }
      }
    ) {
      frontmatter {
        title
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
