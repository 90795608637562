import * as React from "react";
import PropTypes from "prop-types";

import HomePageHero from "../../components/HomepageHero";
import { SupportingCompanies } from "../../components/organisms/supporting-companies";
import { Link } from "gatsby";
import { TextSection } from "../../components/molecules/TextSection";
import { TextAndImageSection } from "../../components/molecules/TextAndImageSection";
import { LayoutBox } from "../../components/atoms/LayoutBox";
import { TopTestimonials } from "../../components/TopTestimonials";

export const IndexPageTemplate = ({
  title,
  subheading,
  storyIntro,
  whatWeDo,
  ourStoryTitle,
  ourStoryFeaturedImage,
  siteDescription = "Millions of people live with or care for someone with neurological conditions such as stroke, brain injury or dementia. We are here to help.",
}) => {
  return (
    <>
      <HomePageHero>
        <div className="hero-body">
          <div className="container is-flex is-flex-direction-column is-justify-content-center">
            <div className="columns">
              <div className="column is-6">
                <div className="section p-0">
                  <h1
                    className="title is-size-3-mobile is-size-2-tablet is-size-1-widescreen pl-0 pr-0 pb-1"
                    style={{
                      color: "white",
                      lineHeight: "1.2",
                    }}
                  >
                    {title}
                  </h1>
                  <h2
                    className="subtitle has-text-weight-normal is-size-5-mobile is-size-5-tablet is-size-4-widescreen pl-0 pr-0 pt-1"
                    style={{
                      color: "white",
                      lineHeight: "1.4",
                    }}
                  >
                    {subheading}
                  </h2>
                  <div className="buttons is-flex-direction-column is-align-items-start">
                    <Link
                      to="/for-survivors"
                      className={`button is-rounded is-primary`}
                    >
                      I have a Neurological Condition or Injury
                    </Link>
                    <Link
                      to="/for-carers"
                      className={`button is-rounded is-primary`}
                    >
                      I'm a Family Member or Caregiver
                    </Link>
                    <Link
                      to="/for-professionals"
                      className={`button is-rounded is-primary`}
                    >
                      I'm a Professional
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero is-dark-blue is-small">
          <LayoutBox>
            <p className="subtitle has-text-weight-normal is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-centered">
              {siteDescription}
            </p>
          </LayoutBox>
        </div>
      </HomePageHero>

      <TextAndImageSection
        title={ourStoryTitle}
        mainText={storyIntro}
        image={ourStoryFeaturedImage}
      >
        <div className="level">
          <div className="level-left">
            <Link
              className="button is-rounded is-medium has-text-info"
              to="/blog/our-story"
            >
              Read More →
            </Link>
          </div>
        </div>
      </TextAndImageSection>

      <TextSection
        title="What we do"
        mainText={whatWeDo}
        hasAltBackgroundColor={true}
      >
        <div className="level">
          <div className="level-left">
            <Link
              to="/app-link?install"
              className="button is-rounded is-medium is-primary"
            >
              Get Started
            </Link>
          </div>
        </div>
      </TextSection>
      <TextSection title="Testimonials">
        <TopTestimonials />
      </TextSection>
      <SupportingCompanies />
    </>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  storyIntro: PropTypes.string,
};
