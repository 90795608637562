import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import { Testimonial } from "./molecules/Testimonial";

export const TopTestimonials = () => {
  const queryResult = useStaticQuery(graphql`
    query TopThreeTestimonialsQuery {
      markdownRemark(
        frontmatter: { templateKey: { eq: "testimonials-page" } }
      ) {
        frontmatter {
          title
          meta_title
          meta_description
          testimonials {
            quote
            author
            homepage
            featured
          }
        }
      }
    }
  `);

  const liveTestimonials =
    queryResult.markdownRemark.frontmatter.testimonials.filter(
      (t) => t.featured
    );
  const homepageTestimonials = liveTestimonials.filter((t) => t.homepage);

  return (
    <>
      <div className="columns is-multiline">
        {homepageTestimonials.map(({ quote, author }) => (
          <div className="column is-one-third">
            <Testimonial quote={quote} author={author} />
          </div>
        ))}
      </div>
      {liveTestimonials.length > homepageTestimonials.length && (
        <div className="level is-flex-direction-row-reverse pb-0">
          <div>
            <Link
              className="button is-rounded is-medium has-text-info"
              to="/testimonials"
            >
              More →
            </Link>
          </div>
        </div>
      )}
    </>
  );
};
