import React from "react";

export const Testimonial = ({ quote, author }) => {
  return (
    <article
      className="card has-background-white-bis is-flex is-flex-direction-column"
      style={{ height: "100%" }}
    >
      <div className="subtitle is-5 card-content pb-0 is-flex-grow-1">
        {quote}
      </div>
      <div className="card-content pt-3 is-flex is-flex-direction-row-reverse has-text-right">
        <span className="subtitle is-6 has-text-secondary-purple is-italic">
          – {author}
        </span>
      </div>
    </article>
  );
};
