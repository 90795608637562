import React from "react";
import PropTypes from "prop-types";

export default function HomepageHero(props) {
  const { children } = props;

  return (
    <div className="hero-homepage is-fullheight-with-navbar">{children}</div>
  );
}

HomepageHero.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  height: PropTypes.number,
};
